import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import getClaimNotesByClaimId from '../../../../../shared/hooks/useClaimNotesByClaimId';

const ClaimNotesList = ({ claimId }) => {
  // Fetch claim notes using the custom hook
  const { claimNotes, claimNotesIsLoading, claimNotesHasError } = getClaimNotesByClaimId(claimId);

  if (claimNotesIsLoading) {
    return <div>Loading...</div>;
  }

  if (claimNotesHasError) {
    return <div>Error loading claim notes.</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claimNotes.map((note) => (
            <TableRow key={note.id}>
              <TableCell>{note.user}</TableCell>
              <TableCell>{note.note}</TableCell>
              <TableCell>{new Date(note.created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClaimNotesList;
