import React from 'react';
import { TableRow, TableCell } from '@mui/material';

const PaymentEventRow = ({ event }) => {
  // Extract the status from the event
  const { id, patient_id, patient_ctrl_number, charge, claim_payment, payer_name, claim_status_code, payment_date, check_number } = event;
  
  // Determine statusType based on claim_status_code
  let statusType;
  let statusMessage;
  const claimStatusCode = event.claim_status_code || null;
  if (claimStatusCode === "1" || claimStatusCode === "19") {
    statusType = 1; // Primary
    statusMessage = "Primary"
  } else if (claimStatusCode === "2") {
    statusType = 2; // Secondary
    statusMessage = "Secondary"
  } else if (claimStatusCode === "22") {
    statusType = 3; // Clawback
    statusMessage = "Clawback"
  } else {
    statusType = 4; // Manual Adjustment or other
    statusMessage = "Manual Adjustment"
  }

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{statusMessage}</TableCell>
      <TableCell>{patient_id}</TableCell>
      <TableCell>{claim_payment}</TableCell>
      <TableCell>{payer_name}</TableCell>
      <TableCell>{`${claim_status_code} - ${statusType}`}</TableCell>
      <TableCell>{payment_date}</TableCell>
      <TableCell>{check_number}</TableCell>
    </TableRow>
  );
};

export default PaymentEventRow;
