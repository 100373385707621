import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Dashboard.scss';
import './../../style.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal/Modal';
import HeaderSidebar from '../../components/HeaderSidebar/HeaderSidebar';

const Dashboard = () => {
    const { user, isLoading, isAuthenticated } = useAuth0();
    // console.log(user);
    return (
                
        <div className="dashboard">
            <HeaderSidebar title="Home" subcategory="" subtitle="Dashboard" />
            <main className="main-content">
                <div className="content-area">
                    <h1>Dashboard</h1>
                    <small>{user.name}</small>
                    
                </div>
            </main>
        </div>        

    );
}

export default Dashboard;