import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Sidebar.scss';
import NewLogo from './../../images/new-logo.png';
import Brand from './../../images/new-brand.png';
import SidebarConfig from './SidebarConfig';
import { useLocation } from 'react-router-dom';

export default function Sidebar(props) {
    let location = useLocation().pathname;
    location = location.split('/')[1];

    const { user, logout, isAuthenticated } = useAuth0();
    const [sidebar, setSidebar] = useState(false); // sidebar showing full
    const [sidebarSub, setSidebarSub] = useState(false); // sidebar showing full
    const [linkState, setLinkState] = useState(''); // set the active link state for the sidebar
    const [hoverTimeout, setHoverTimeout] = useState(null); // state for managing the hover timeout

    useEffect(() => {
        const sidebarLinks = document.querySelectorAll('.nav-link');
        for (let i = 0; i < sidebarLinks.length; i++) {
            if (sidebarLinks[i].querySelector('a').getAttribute('data-type') === location) {
                sidebarLinks[i].classList.add('link-active');
            } else {
                sidebarLinks[i].classList.remove('link-active');
            }
        }
    });

    let mainContent = document.querySelector('.main-content');

    const openSubMenu = (e, cat) => {
        e.preventDefault();
        let currentLink = cat;
        let internalSidebarLinks = document.querySelector('.internal-sidebar-links');
        internalSidebarLinks.innerHTML = '';

        let title = document.querySelector('.sidebar-sub .title');
        title.innerHTML = SidebarConfig[currentLink].Title;

        // loop through the sidebar config and add the links to the internal sidebar
        for (let i = 0; i < SidebarConfig[currentLink].Links.length; i++) {
            let link = SidebarConfig[currentLink].Links[i];
            let li = document.createElement('li');
            let a = document.createElement('a');
            a.href = link.Link;
            a.innerHTML = link.Title;
            li.appendChild(a);
            internalSidebarLinks.appendChild(li);
        }
    };

    const closeSubMenu = () => {
        setSidebarSub(false);
        mainContent.classList.remove('main-content-shift');
    };

    const sidebarShift = () => {
        if (sidebarSub === true && props.sidebarState === 'closed') {
            mainContent.classList.add('main-content-shift');
            return 'sidebar-sub sidebar-sub-shift';
        } else {
            mainContent.classList.remove('main-content-shift');
            return '';
        }
    };

    const handleMouseEnter = (event, category) => {
        // Set a timeout for 500ms before triggering sidebarSub
        const timeoutId = setTimeout(() => {
            setSidebarSub(true);
            openSubMenu(event, category);
        }, 500);

        setHoverTimeout(timeoutId);
    };

    const handleMouseLeave = () => {
        // Clear the timeout if the user hovers away before the timeout finishes
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
    };

    return (
        <>
            <aside className={props.sidebarClass}>
                <header>
                    <div className="image-text">
                        <span className="image">
                            <img src={Brand} alt="" className={props.smallLogo} />
                            <img src={NewLogo} alt="" className={props.bigLogo} />
                        </span>
                    </div>
                </header>

                <div className="menu-bar">
                    <div className="menu">
                        <ul className="menu-links">
                            <li className="nav-link">
                                <a href="/">
                                    <i className='bx bx-home icon'></i>
                                    <span className="text nav-text">Home</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'patients')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/patients" data-type="patients">
                                    <i className='bx bx-male-female icon'></i>
                                    <span className="text nav-text">Patients</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'providers')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/providers" data-type="providers">
                                    <i className='bx bx-building-house icon'></i>
                                    <span className="text nav-text">Providers</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'products')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/products" data-type="products">
                                    <i className='bx bx-package icon'></i>
                                    <span className="text nav-text">Products</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'insurance')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/insurance" data-type="insurance">
                                    <i className='bx bx-clipboard icon'></i>
                                    <span className="text nav-text">Insurance</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'payers')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/payers" data-type="payers">
                                    <i className='bx bx-cog icon'></i>
                                    <span className="text nav-text">Payers</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'billing')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="#" data-type="billing">
                                    <i className='bx bx-credit-card icon'></i>
                                    <span className="text nav-text">Billing</span>
                                </a>
                            </li>
                            <li className="nav-link"
                                onMouseEnter={(event) => handleMouseEnter(event, 'maintenance')}
                                onMouseLeave={handleMouseLeave}>
                                <a href="/maintenance" data-type="maintenance">
                                    <i className='bx bx-wrench icon'></i>
                                    <span className="text nav-text">Maintenance</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar-bottom">
                        <li>
                            <a href="#" onClick={() => logout()}>
                                <i className='bx bx-log-out icon'></i>
                                <span className="text nav-text">Logout</span>
                            </a>
                        </li>

                        <li className="mode">
                            <div className="moon-sun">
                                <i className='bx bx-moon icon moon'></i>
                                <i className='bx bx-sun icon sun'></i>
                            </div>
                            <span className="mode-text text">Dark Mode</span>

                            <div className="toggle-switch">
                                <span className="switch"></span>
                            </div>
                        </li>
                    </div>
                </div>
            </aside>
            <div className={
                sidebarSub === false
                    ? "sidebar-sub"
                    : "sidebar-sub sidebar-sub-active" + sidebarShift()
            }>
                <a href="#" className="close-sidebar-sub" onClick={closeSubMenu}><i className='bx bx-x icon'></i></a>
                <h4 className="title"></h4>
                <ul className="internal-sidebar-links">

                </ul>
            </div>
        </>
    );
}
