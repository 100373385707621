import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import HeaderContextMenu from "./../HeaderContextMenu/HeaderContextMenu";
import './../HeaderContextMenu/HeaderContextMenu.scss';

import "./Header.scss";

const Header = (props) => {
    const { user } = useAuth0();

    let [contextMenu, setContextMenu] = useState(false);

    const handleContextMenu = () => {
        setContextMenu(!contextMenu);
    }

    return (
        <>
            <header className={props.headerClass}>
                <div className="header-start">
                    <div className="header-title">
                        <h3>{props.title} <span>{props.subcategory !== '' ? '> ' + props.subcategory : '' }</span></h3>
                        <small>{props.subtitle}</small>
                    </div>
                </div>        
                <div className="header-end">
                    <span className="search-box">
                        <i className="bx bx-search icon"></i>
                        <input type="search" placeholder="Search Patient..."/>
                    </span>
                    <div className="fullscreen">
                        <i className='bx bx-fullscreen header-icon'></i>
                    </div>
                    
                    <div className="notification">
                        <span className="notify-badge"></span>
                        <i className='bx bx-bell header-icon'></i>
                    </div>    
                    <div className="profile-contain" onClick={handleContextMenu}>
                        <span className="profile-text">{user.name} <i className='bx bx-chevron-down profile-caret'></i></span>
                    </div>
                    <div className={contextMenu === false ? "header-context-menu" : "header-context-menu active"}>
                        <ul>
                            <li><a href="#"><i className='bx bx-toggle-left icon'></i> Dark Mode</a></li>
                            <div className="split-line"></div>
                            <li><a href="#"><i className='bx bx-mail-send icon'></i> Email Settings</a></li>
                            <li><a href="#"><i className='bx bx-lock-alt icon'></i> Reset Password</a></li>
                            <div className="split-line"></div>
                            <li><a href="/profile"><i className='bx bx-happy-alt icon'></i> Profile Info</a></li>
                            <li><a href="#"><i className='bx bx-log-out icon'></i> Logout</a></li>
                        </ul>
                    </div>
                </div>
            </header>
            {/* <div className="utility-bar">
                <div className="utility-left">
                    {props.utilityTitle}
                </div>
                <div className="utility-right">
                    <button className="primary-button">Create Patient</button>
                </div>
            </div> */}
        </>
    );
}

export default Header;