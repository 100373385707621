import apiClient from '../../api/apiClient';

export const getAllClaims = async () => {
    try {
        const response = await apiClient.get('/billing/claims');
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const unprocessedClaims = async () => {
    try {
        const response = await apiClient.get(`/billing/claims/get-associated-payment-events`);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching unprocessed claims form payment_events:`, error);
        throw error;
    }
};

export const getClaimById = async (claimId) => {
    try {
        const response = await apiClient.get(`/billing/claims/${claimId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching claim with ID ${claimId}:`, error);
        throw error;
    }
};

export const createClaim = async (claimData) => {
    try {
        const response = await apiClient.post('/billing/claims', claimData);
        return response.data;
    } catch (error) {
        console.error('Error creating claim:', error);
        throw error;
    }
};

export const updateClaim = async (claimId, updatedData) => {
    try {
        const response = await apiClient.patch(`/billing/claims/${claimId}`, updatedData);
        return response.data;
    } catch (error) {
        console.error(`Error updating claim with ID ${claimId}:`, error);
        throw error;
    }
};