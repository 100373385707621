import apiClient from '../../api/apiClient';

export const getAllClaimNotes = async () => {
    try {
        const response = await apiClient.get('/billing/claim-notes');
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getClaimNotesById = async (claimId) => {
    try {
        const response = await apiClient.get(`/billing/claim-notes/${claimId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching claim notes with ID: ${claimId}`);
        throw error;
    }
}

export const getClaimNotesByClaimId = async (claimId) => {
    try {
        const response = await apiClient.get(`/billing/claim-notes/claim-detail/${claimId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching claim notes with ID: ${claimId}`);
        throw error;
    }
}

export const createClaimNote = async (claimId, claimNotes) => {
    try {
        // console.log(claimNotes);
        let claimData = [];
        
        // Rebuilding the object with correct mapping and data
        for(let i=0; i<claimNotes.length; i++){
            claimData.push({
                claim_id: claimId,
                user: claimNotes[i].userName,
                note: claimNotes[i].text,
                user_id: claimNotes[i].userId
            });
        }

        // Loop through claimData and send each note individually
        for(let i=0; i<claimData.length; i++){
            const response = await apiClient.post('/billing/claim-notes', claimData[i]);
        }
        
        return true;
    } catch (error) {
        console.log('Error creating Claim Note ', error);
        throw error;
    }
}