import React, { useState } from "react";
import "./AddClaimModal.scss";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../../../style.scss";
import { createClaim } from "../../../../../shared/services/claimService";
import { createPaymentEvent } from '../../../../../shared/services/paymentEventService';

const AddClaimModal = ({ className }) => {

  const { user, isLoading: authLoading } = useAuth0();
  const userPayload = {
    userName: user.name,
    userId: user.sub.split("|")[1],
  };

  const [isOpen, setIsOpen] = useState(false); // Manage the open/close state internally
  const [totalCharge, setTotalCharge] = useState("");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [cptCode, setCptCode] = useState("");
  const [patientCtrlNumber, setPatientCtrlNumber] = useState("");
  const [memberMbiNumber, setMemberMbiNumber] = useState("");
  const [units, setUnits] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [cpfsid, setCpfsid] = useState(2);
  const [claimPayerName, setClaimPayerName] = useState("");
  

  // Remit Data
  const [statementStart, setStatementStart] = useState("");
  const [payerName, setPayerName] = useState("");
  const [allowedAmount, setAllowedAmount] = useState("");
  const [claimPayment, setClaimPayment] = useState("");
  const [patientResp, setPatientResp] = useState("");
  const [remitCptCode, setRemitCptCode] = useState("");
  const [payerIcn, setPayerIcn] = useState("");
  const [claimStatusCode, setClaimStatusCode] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle the modal open/close state
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const claimData = {
      total_charge: parseFloat(totalCharge),
      patient_name: `${patientLastName.toUpperCase()}, ${patientFirstName.toUpperCase()}`,
      payer_name: claimPayerName,
      from_date: fromDate,
      cpt_code: cptCode,
      patient_ctrl_number: patientCtrlNumber,
      patient_id: patientCtrlNumber,
      member_mbi_number: memberMbiNumber,
      units: units,
      cpfsid: cpfsid,
      user_id: userPayload.userId
    };

    const remitData = {
      record_processed: 0,
      statement_start: statementStart,
      patient_ctrl_number: patientCtrlNumber,
      patient_id: patientCtrlNumber,
      payer_name: payerName,
      allowed_amount: allowedAmount,
      claim_payment: claimPayment,
      patient_resp: patientResp,
      cpt_code: remitCptCode,
      check_number: checkNumber,
      payer_icn: payerIcn,
      claim_status_code: claimStatusCode,
      user_id: userPayload.userId
    }

    try {
      console.log('Claims:')
      console.log(claimData);
      console.log('--------')
      console.log(remitData);

      
      const claimInsert = await createClaim(claimData);
      console.log(claimInsert);

      const remitInsert = await createPaymentEvent(remitData);
      console.log(remitInsert);

      toggleModal(); // Close the modal on success
      resetForm();
    } catch (err) {
      console.error("Error adding claim:", err);
      setError("Failed to add claim. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setTotalCharge("");
    setPatientFirstName("");
    setPatientLastName("");
    setFromDate("");
    setCptCode("");
    setPatientCtrlNumber("");
    setMemberMbiNumber("");
    setUnits("");
    setCheckNumber("");
    setClaimPayerName("");
    setStatementStart("");
    setPayerName("");
    setAllowedAmount("");
    setClaimPayment("");
    setPatientResp("");
    setRemitCptCode("");
    setPayerIcn("");
    setClaimStatusCode("");

  };

  return (
    <div>
      <button className={className} onClick={toggleModal}>
        Add New Claim
      </button>

      {/* Modal Slide-Out */}
      <>
        <div
          style={{
            ...styles.modalBackdrop,
            display: isOpen ? "block" : "none",
          }}
          onClick={toggleModal}
        />
        <div
          style={{
            ...styles.slideOutContainer,
            right: isOpen ? "0" : "-1250px",
            transition: "right 0.3s ease-in-out"
          }}
        >
          <button style={styles.closeButton} onClick={toggleModal}>
            &times;
          </button>
          <h2 style={{ fontWeight: 400 }}>Create New Claim</h2>
          <br />
          <br />
          {error && <p className="error">{error}</p>}

          <form onSubmit={handleSubmit}>
            {/* Claim Input fields */}
            <div className="container">
              <div className="contain-50">
                Claim Data
                <br />
                <br />
                <input
                  type="text"
                  value={patientFirstName}
                  onChange={(e) => setPatientFirstName(e.target.value)}
                  placeholder="Enter Patient First Name"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={patientLastName}
                  onChange={(e) => setPatientLastName(e.target.value)}
                  placeholder="Enter Patient Last Name"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={claimPayerName}
                  onChange={(e) => setClaimPayerName(e.target.value)}
                  placeholder="Enter Payer Name"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={totalCharge}
                  onChange={(e) => setTotalCharge(e.target.value)}
                  placeholder="Enter Total Charge"
                />
                <br />
                <br />
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="Enter Date of Service"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={cptCode}
                  onChange={(e) => setCptCode(e.target.value)}
                  placeholder="Enter CPT Code"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={patientCtrlNumber}
                  onChange={(e) => setPatientCtrlNumber(e.target.value)}
                  placeholder="Enter Patient Id"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={memberMbiNumber}
                  onChange={(e) => setMemberMbiNumber(e.target.value)}
                  placeholder="Enter Member MBI Number"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={units}
                  onChange={(e) => setUnits(e.target.value)}
                  placeholder="Enter Number of Units"
                />
                <br />
                <br />
                <input
                  type="text"
                  value={checkNumber}
                  onChange={(e) => setCheckNumber(e.target.value)}
                  placeholder="Enter Check Number"
                />
              </div>
              
              <div className="contain-50">
                
                {/* Remit Input fields */}
                Remit Data
                <br />
                <br />

                <input 
                  type="date" 
                  value={statementStart}
                  onChange={(e) => setStatementStart(e.target.value)}
                  placeholder="Remit Statement Start"
                />
                
                <br />
                <br />
                <input 
                  type="text" 
                  value={payerName}
                  onChange={(e) => setPayerName(e.target.value)}
                  placeholder="Payer Name"
                />
                
                <br />
                <br />
                <input 
                  type="text"
                  value={allowedAmount}
                  onChange={(e) => setAllowedAmount(e.target.value)}
                  placeholder="Allowed Amount"
                />

                <br />
                <br />
                <input 
                  type="text"
                  value={claimPayment}
                  onChange={(e) => setClaimPayment(e.target.value)}
                  placeholder="Claim Payment"
                />
                
                <br />
                <br />
                <input 
                  type="text"
                  value={patientResp}
                  onChange={(e) => setPatientResp(e.target.value)}
                  placeholder="Patient Responsible"
                />
                
                <br />
                <br />
                <input 
                  type="text"
                  value={remitCptCode}
                  onChange={(e) => setRemitCptCode(e.target.value)}
                  placeholder="Remit CPT Code"
                />
                
                <br />
                <br />
                <input 
                  type="text"
                  value={payerIcn}
                  onChange={(e) => setPayerIcn(e.target.value)}
                  placeholder="Remit Payer ICN"
                />

                <br />
                <br />
                <select
                  value={claimStatusCode}
                  onChange={(e) => setClaimStatusCode(e.target.value)}
                >
                  <option value="" disabled>Select Claim Status</option>
                  <option value="1">Primary - Need More Information</option>
                  <option value="19">Primary - Claim Received</option>
                  <option value="2">Secondary</option>
                  <option value="3">Clawback</option>
                  {/* Add more options as needed */}
                </select>
                

                <br /><br />
                <button
                  className="add-claim-modal-cancel-btn"
                  type="button"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                &nbsp;
                <button
                  className="add-claim-modal-submit-btn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>

              <br />
              <br />
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(154, 170, 185, 0.5)",
    zIndex: 1000,
  },
  slideOutContainer: {
    position: "fixed",
    top: 0,
    right: "-1250px", // Initially off-screen
    bottom: 0,
    width: "1250px",
    backgroundColor: "white",
    padding: "40px",
    transition: "right 0.3s ease-in-out", // Smooth slide effect
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.09)",
    zIndex: 1100,
  },
  closeButton: {
    position: "absolute",
    top: "15px",
    right: "20px",
    fontSize: "25px",
    cursor: "pointer",
    border: "none",
    background: "transparent",
    fontWeight: "300",
  },
};

export default AddClaimModal;
