import React, { useState } from 'react';
import './HeaderContextMenu.scss';


const HeaderContextMenu = (props) => {

    let [contextMenu, setContextMenu] = useState(false);

    const handleContextMenu = () => {
        setContextMenu(!contextMenu);
    }

    return (
        <div className="header-context-menu">
            <ul>
                <li><a href="#"><i class='bx bx-toggle-left icon'></i> Dark Mode</a></li>
                <div className="split-line"></div>
                <li><a href="#"><i class='bx bx-mail-send icon'></i> Email Settings</a></li>
                <li><a href="#"><i class='bx bx-lock-alt icon'></i> Reset Password</a></li>
                <div className="split-line"></div>
                <li><a href="#"><i class='bx bx-happy-alt icon'></i> Profile Info</a></li>
                <li><a href="#"><i class='bx bx-log-out icon'></i> Logout</a></li>
            </ul>
        </div>
    )
}

export default HeaderContextMenu;