import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";


const HeaderSidebar = (props) => {
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
        let mainContent = document.querySelector('.main-content');
        mainContent.classList.toggle('main-content-expanded');
    }

    return (
        <>
            <i 
                className={isActive === false ? "bx bx-chevron-left toggle" : "bx bx-chevron-right toggle"}
                onClick={toggleActive}
            ></i>
            <Sidebar 
                sidebarClass={isActive === false ? "sidebar" : "sidebar close"} 
                sidebarState={isActive === false ? "open" : "closed"}
                smallLogo={isActive === false ? "small-logo hide-logo" : "small-logo"}
                bigLogo={isActive === false ? "big-logo" : "big-logo hide-logo"}
                sidebarSubClass={isActive === false ? "sidebar-sub" : "sidebar-sub sidebar-sub-active"}
            />
            <Header 
                title={props.title} 
                subcategory={props.subcategory} 
                subtitle={props.subtitle}
                utilityTitle={props.utilityTitle}
                headerClass={isActive === false ? "top-header" : "top-header top-header-expanded"}
            />
        </>
    );
}

export default HeaderSidebar;