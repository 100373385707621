import { useState, useEffect } from 'react';
import { getClaimNotesByClaimId } from '../services/claimNoteService';

const useClaimNotesByClaimId = (claimId) => {

    const [ claimNotes, setClaimNotes ] = useState([]);
    const [ claimNotesIsLoading, setClaimNotesIsLoading ] = useState(true);
    const [ claimNotesHasError, setClaimNotesHasError ] = useState(null);

    useEffect(() => { 
        const fetchClaimNotes = async () => {
            try {
                const claimNotesData = await getClaimNotesByClaimId(claimId);
                if(!claimNotes){
                    throw new Error('No Claim Notes Found');
                }
                setClaimNotes(claimNotesData);
            } catch (error) {
                console.log('Error in fetchClaimNotes', error)
                setClaimNotesHasError(error);
            } finally {
                setClaimNotesIsLoading(false);
            }
        }
        fetchClaimNotes();
    }, [claimId]);

    return { claimNotes, claimNotesIsLoading, claimNotesHasError };

}

export default useClaimNotesByClaimId;