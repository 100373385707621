import apiClient from '../../api/apiClient';

export const getAllPaymentEvents = async () => {
    try {
        
        const response = await apiClient.get('/billing/payment-events');
        return response.data.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getPaymentEventById = async (paymentEventId) => {
    try {
        const response = await apiClient.get(`/billing/payment-events/${paymentEventId}`);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getMultiplePaymentEvents = async (paymentEventIds) => {
    try {
        const payload = { ids: paymentEventIds }
        
        const response = await apiClient.post('/billing/payment-events/multiple-payment-events', payload);
        return response.data.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const updatePaymentEvent = async (paymentEventId, updatedData) => {
    try {

        const response = await apiClient.patch(`/billing/payment-events/${paymentEventId}`, updatedData);
        return response.data.data;
        
    } catch (error) {
        console.log(error);
        throw error;
    }

};

export const createPaymentEvent = async (paymentEventData, userId) => {

    try {
        const response = await apiClient.post('/billing/payment-events', paymentEventData);
        return response.data.data;

    } catch (error) {
        console.log(error);
        throw error;
    }

};