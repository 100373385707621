import apiClient from '../../api/apiClient';

export const getAllClaimStatuses = async () => {
    try {
        const response = await apiClient.get('/billing/claim-status');
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getClaimStatusById = async (id) => {
    try {
        const response = await apiClient.get(`/billing/claim-status/${id}`);
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}