import { useState, useEffect } from 'react';
import { getAllPaymentEventTypes } from '../../../src/shared/services/paymentEventTypeService';

const usePaymentEventTypesAll = () => {
    const [ paymentEventTypes, setPaymentEventTypes ] = useState([]);
    const [ paymentEventTypesIsLoading, setPaymentEventTypesIsLoading ] = useState(null);
    const [ paymentEventTypesError, setPaymentEventTypesError ] = useState(null);

    useEffect(() => {
        const fetchPaymentEventTypes = async () => {
            try {
                const paymentEventTypesData = await getAllPaymentEventTypes();
                if(!paymentEventTypesData){
                    throw new Error('No Payment Event Types data received');
                }
                setPaymentEventTypes(paymentEventTypesData);
            } catch (error) {
                console.error('Error in fetchPaymentEventTypes', error);
                setPaymentEventTypesError(error);
            } finally {
                setPaymentEventTypesIsLoading(false);
            }
        }
        fetchPaymentEventTypes();
    }, []);

    return { paymentEventTypes, paymentEventTypesIsLoading, paymentEventTypesError };

}

export default usePaymentEventTypesAll;