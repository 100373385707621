import React, { useState } from 'react';
import usePaymentEventTypesAll from '../../../../../shared/hooks/usePaymentEventTypesAll';
import './AddPaymentModal.scss';

function AddPaymentModal({ onSubmit, className }) {
  const [isOpen, setIsOpen] = useState(false);
  const [payment, setPayment] = useState('');
  const [selectedEventType, setSelectedEventType] = useState('');
  const [allowedAmount, setAllowedAmount] = useState('');
  const [claimPayment, setClaimPayment] = useState('');
  const [patientResp, setPatientResp] = useState('');
  const [statusType, setStatusType] = useState('');
  const [payerName, setPayerName] = useState('');
  const [cptCode, setCptCode] = useState('');
  const [statementStart, setStatementStart] = useState('');
  const [claimAdjCodes, setClaimAdjCodes] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [paymentTypeId, setPaymentTypeId] = useState('');

  const { paymentEventTypes, paymentEventTypesIsLoading, paymentEventTypesError } = usePaymentEventTypesAll();

  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
  };

  const handleEventTypeChange = (event) => {
    const eventType = event.target.value;
    setSelectedEventType(eventType);

    if (eventType !== '1') {
      // For event types other than 'remit', set paymentTypeId to selectedEventType
      setPaymentTypeId(parseInt(eventType, 10));
    } else {
      // For 'remit', paymentTypeId will be determined based on statusType
      setPaymentTypeId('');
    }

    // Reset fields when event type changes
    setPayment('');
    setAllowedAmount('');
    setClaimPayment('');
    setPatientResp('');
    setStatusType('');
    setPayerName('');
    setCptCode('');
    setStatementStart('');
    setClaimAdjCodes('');
    setCheckNumber('');
  };

  const handleAllowedAmountChange = (event) => {
    setAllowedAmount(event.target.value);
  };

  const handleClaimPaymentChange = (event) => {
    setClaimPayment(event.target.value);
  };

  const handlePatientRespChange = (event) => {
    setPatientResp(event.target.value);
  };

  const handleStatusTypeChange = (event) => {
    const status = event.target.value;
    setStatusType(status);

    if (status === '3') { // Clawback
      setPaymentTypeId(14);
    } else if (status === '1' || status === '2') { // Primary or Secondary
      setPaymentTypeId(1);
    } else {
      // For other statusType, set paymentTypeId to selectedEventType
      setPaymentTypeId(parseInt(selectedEventType, 10));
    }
  };

  const handlePayerNameChange = (event) => {
    setPayerName(event.target.value);
  };

  const handleCptCodeChange = (event) => {
    setCptCode(event.target.value);
  };

  const handleStatementStart = (event) => {
    setStatementStart(event.target.value);
  };

  const handleClaimAdjCodes = (event) => {
    setClaimAdjCodes(event.target.value);
  };

  const handleCheckNumber = (event) => {
    setCheckNumber(event.target.value);
  };

  const addPayment = () => {
    if (onSubmit) {
      onSubmit({
        payment,
        eventType: selectedEventType,
        allowedAmount,
        claimPayment,
        patientResp,
        statusType,
        payerName,
        cptCode,
        statementStart,
        claimAdjCodes,
        checkNumber,
        paymentTypeId, // Include paymentTypeId here
      }); // Call the callback with payment data and additional remit details
    }
    // Reset states after submission
    setPayment('');
    setSelectedEventType('');
    setAllowedAmount('');
    setClaimPayment('');
    setPatientResp('');
    setStatusType('');
    setPayerName('');
    setCptCode('');
    setStatementStart('');
    setClaimAdjCodes('');
    setCheckNumber('');
    setPaymentTypeId('');
    setIsOpen(false); // Close modal after submission
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addPayment();
  };

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle modal visibility
  };

  return (
    <div>
      <button className={className} onClick={toggleModal}>+</button>

      {isOpen && (
        <div style={styles.modalBackdrop}>
          <div style={styles.modal}>
            <h2>Add Payment</h2>
            <form onSubmit={handleSubmit}>
              {paymentEventTypesIsLoading ? (
                <p>Loading payment types...</p>
              ) : paymentEventTypesError ? (
                <p>Error loading payment types</p>
              ) : (
                <select value={selectedEventType} onChange={handleEventTypeChange}>
                  <option value="" disabled>Select payment type</option>
                  {paymentEventTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              )}
              <br /><br />
              {selectedEventType !== '1' && selectedEventType && ( // If not "remit" type, show payment amount field
                <>
                  <input
                    type="text"
                    value={payment}
                    onChange={handlePaymentChange}
                    placeholder="Enter payment amount"
                  />
                  <br /><br />
                </>
              )}
              {selectedEventType === '1' && ( // If "remit" type is selected, show additional fields
                <>
                  <select value={statusType} onChange={handleStatusTypeChange}>
                    <option value="" disabled>Select status type</option>
                    <option value="1">Primary</option>
                    <option value="2">Secondary</option>
                    <option value="3">Clawback</option>
                    {/* Add additional status types here if needed */}
                  </select>
                  <br /><br />

                  <input
                    type="text"
                    value={payerName}
                    onChange={handlePayerNameChange}
                    placeholder="Enter payer name"
                  />
                  <br /><br />

                  <input
                    type="date"
                    value={statementStart}
                    onChange={handleStatementStart}
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={cptCode}
                    onChange={handleCptCodeChange}
                    placeholder="Enter CPT Code"
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={claimAdjCodes}
                    onChange={handleClaimAdjCodes}
                    placeholder="Enter Claim Adjustment Codes"
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={allowedAmount}
                    onChange={handleAllowedAmountChange}
                    placeholder="Enter allowed amount"
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={claimPayment}
                    onChange={handleClaimPaymentChange}
                    placeholder="Enter claim payment"
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={patientResp}
                    onChange={handlePatientRespChange}
                    placeholder="Enter patient responsibility"
                  />
                  <br /><br />

                  <input
                    type="text"
                    value={checkNumber}
                    onChange={handleCheckNumber}
                    placeholder="Enter Check Number"
                  />
                  <br /><br />
                </>
              )}
              <button className="add-payment-modal-cancel-btn" type="button" onClick={toggleModal}>Cancel</button>&nbsp;
              <button className="add-payment-modal-submit-btn" type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(154, 170, 185, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2000',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    textAlign: 'center',
  },
};

export default AddPaymentModal;
