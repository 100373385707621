import React, { useState, useEffect } from "react";
import AddPaymentModal from "../AddPaymentModal/AddPaymentModal";
import "./RemitRow.scss";
import ClaimStatusSelect from "../ClaimStatusSelect/ClaimStatusSelect";
import ClaimNotes from "../ClaimNotes/ClaimNotes";
import ClaimConfirmationModal from "../ClaimConfirmationModal/ClaimConfirmationModal";

const RemitRow = ({
  paymentEvents,
  claimId,
  patientId,
  userPayload,
  claimPayer,
  onUpdateRemit,
  onSubmit,
}) => {
  const [remits, setRemits] = useState([]);
  const [primaryTotal, setPrimaryTotal] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [editedRemit, setEditedRemit] = useState({});
  const [addedPayments, setAddedPayments] = useState([]);
  const [missingPrimaryWarning, setMissingPrimaryWarning] = useState(false);
  const [subtotal, setSubtotal] = useState(0);

  const [selectedClaimStatus, setSelectedClaimStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [claimNote, setClaimNote] = useState("");
  const [notesList, setNotesList] = useState([]);

  const [isClaimNotesSlideOutOpen, setIsClaimNotesSlideOutOpen] = useState(false);

  // State for confirmation modal
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Function to generate unique IDs
  const generateUniqueId = () => {
    return `manual-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  // Rounding function
  const roundToTwo = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const handleClaimStatusChange = (newStatus) => {
    setSelectedClaimStatus(newStatus);
  };

  const claimNotesHandleAdd = (e) => {
    e.preventDefault();
    if (claimNote.trim()) {
      setNotesList([
        ...notesList,
        {
          text: claimNote,
          userId: userPayload.userId,
          userName: userPayload.userName,
          date: new Date().toISOString(),
        },
      ]);
      setClaimNote("");
    }
  };

  const openClaimNotesSlideOut = () => setIsClaimNotesSlideOutOpen(true);
  const closeClaimNotesSlideOut = () => setIsClaimNotesSlideOutOpen(false);

  // Normalize remits and calculate primary and secondary remit values
  useEffect(() => {
    if (paymentEvents && paymentEvents.length > 0) {
      const normalizedRemits = paymentEvents.map((event) => {
        const allowedAmount = parseFloat(event.allowed_amount) || 0;
        const claimPayment = parseFloat(event.claim_payment) || 0;
        const patientResp = parseFloat(event.patient_resp) || 0;
        const payerName = event.payer_name;
        const statementStart = event.statement_start;
        const checkNumber = event.check_number || "";

        let sequestration = 0;
        let payableAmount = 0;
        let summary = 0;
        let statusType = null;

        // Determine statusType based on claim_status_code
        const claimStatusCode = event.claim_status_code || null;
        if (claimStatusCode === "1" || claimStatusCode === "19") {
          statusType = 1; // Primary
        } else if (claimStatusCode === "2") {
          statusType = 2; // Secondary
        } else if (claimStatusCode === "22") {
          statusType = 3; // Clawback
        } else {
          statusType = 4; // Manual Adjustment or other
        }

        // Calculate values based on statusType
        if (statusType === 1) {
          // Primary
          sequestration = roundToTwo(
            allowedAmount - claimPayment - patientResp
          );
          payableAmount = roundToTwo(allowedAmount - sequestration);
          summary = roundToTwo(payableAmount - claimPayment);
        } else if (statusType === 2) {
          // Secondary
          sequestration = 0;
          summary = roundToTwo(claimPayment);
        } else if (statusType === 3) {
          // Clawback
          sequestration = 0;
          summary = roundToTwo(claimPayment);
        } else {
          // Manual Adjustment or other
          payableAmount = parseFloat(event.payable_amount) || 0;
          summary = roundToTwo(payableAmount);
        }

        return {
          id: event.id,
          allowedAmount,
          payableAmount,
          claimPayment,
          patientResp,
          sequestration,
          payerName,
          statementStart,
          summary,
          claimStatusCode,
          checkNumber,
          manualPaymentAdjustment:
            parseFloat(event.manual_payment_adjustment) || 0,
          cpt_code: event.cpt_code || "",
          statusType,
        };
      });

      const sortedRemits = [...normalizedRemits, ...addedPayments].sort(
        (a, b) => a.statusType - b.statusType
      );

      setRemits(sortedRemits);
    } else {
      setRemits([...addedPayments]);
    }
  }, [paymentEvents, addedPayments]);

  const recalculatePrimaryTotal = (remitsToUse) => {
    const primaryTotalValue = remitsToUse
      .filter((remit) => remit.statusType === 1)
      .reduce((total, remit) => total + remit.summary, 0);
    setPrimaryTotal(roundToTwo(primaryTotalValue));
  };

  const calculateSubtotal = (remitsToUse) => {
    const primary = remitsToUse.find((remit) => remit.statusType === 1);
    const secondaryTotal = roundToTwo(
      remitsToUse
        .filter((remit) => remit.statusType === 2)
        .reduce((total, remit) => total + remit.summary, 0)
    );
    const manualAdjustmentsTotal = roundToTwo(
      remitsToUse
        .filter((remit) => remit.statusType === 4)
        .reduce((total, remit) => total + remit.summary, 0)
    );
    const clawbackTotal = roundToTwo(
      remitsToUse
        .filter((remit) => remit.statusType === 3)
        .reduce((total, remit) => total + remit.summary, 0)
    );
    let subtotalValue;
    if (primary) {
      subtotalValue = roundToTwo(
        primary.summary -
          secondaryTotal -
          manualAdjustmentsTotal +
          clawbackTotal
      );
    } else {
      subtotalValue = roundToTwo(
        secondaryTotal - manualAdjustmentsTotal + clawbackTotal
      );
    }

    // Debugging logs to verify calculations
    console.log("Subtotal Calculation Debug:", {
      secondaryTotal,
      manualAdjustmentsTotal,
      clawbackTotal,
      subtotalValue,
      primarySummary: primary ? primary.summary : "No Primary",
    });

    setSubtotal(subtotalValue);
  };

  const checkMissingPrimary = (remitsToUse) => {
    const hasPrimary = remitsToUse.some((remit) => remit.statusType === 1);
    setMissingPrimaryWarning(!hasPrimary);
  };

  const recalculateTotals = (remitsToUse) => {
    recalculatePrimaryTotal(remitsToUse);
    calculateSubtotal(remitsToUse);
    checkMissingPrimary(remitsToUse);
  };

  // Recalculate totals whenever remits or edits change
  useEffect(() => {
    let remitsToUse = remits;
    if (editMode !== null) {
      remitsToUse = remits.map((remit) =>
        remit.id === editedRemit.id ? editedRemit : remit
      );
    }
    recalculateTotals(remitsToUse);
  }, [remits, editedRemit]);

  const handleEditClick = (remit) => {
    setEditMode(remit.id);
    setEditedRemit(remit);
  };

  const handleInputChange = (e, field) => {
    const updatedValue = parseFloat(e.target.value) || 0;
    const updatedRemit = { ...editedRemit, [field]: updatedValue };

    // Recalculate values dynamically based on changes, including sequestration and other fields
    if (updatedRemit.statusType === 1) {
      // Primary
      updatedRemit.sequestration = roundToTwo(
        updatedRemit.allowedAmount -
          updatedRemit.claimPayment -
          updatedRemit.patientResp
      );
      updatedRemit.payableAmount = roundToTwo(
        updatedRemit.allowedAmount - updatedRemit.sequestration
      );
      updatedRemit.summary = roundToTwo(
        updatedRemit.payableAmount - updatedRemit.claimPayment
      );
    } else if (updatedRemit.statusType === 2) {
      // Secondary
      updatedRemit.sequestration = 0;
      updatedRemit.summary = roundToTwo(updatedRemit.claimPayment);
    } else if (updatedRemit.statusType === 3) {
      // Clawback
      updatedRemit.sequestration = 0;
      updatedRemit.summary = roundToTwo(updatedRemit.claimPayment);
    } else if (updatedRemit.statusType === 4) {
      // Manual Adjustment
      updatedRemit.sequestration = 0;
      updatedRemit.summary = roundToTwo(updatedRemit.claimPayment);
    } else {
      // Default case
      updatedRemit.sequestration = 0;
      updatedRemit.summary = roundToTwo(updatedRemit.claimPayment);
    }

    setEditedRemit(updatedRemit);
  };

  const handleSaveEdit = (id) => {
    const updatedRemits = remits.map((remit) =>
      remit.id === id ? { ...editedRemit, id } : remit
    );
    setRemits(updatedRemits);
    setEditMode(null);

    onUpdateRemit && onUpdateRemit(editedRemit);
  };

  const handleAddPayment = (data) => {
    const newId = generateUniqueId(); // Generate a unique ID

    let newPayment = {
      id: newId,
      allowedAmount: parseFloat(data.allowedAmount) || 0,
      claimPayment: parseFloat(data.claimPayment) || 0,
      patientResp: parseFloat(data.patientResp) || 0,
      sequestration: 0,
      summary: 0,
      claimStatusCode: data.statusType || null,
      manualPaymentAdjustment: parseFloat(data.payment) || 0,
      cpt_code: data.cptCode || "",
      statusType: parseInt(data.statusType) || 4, // Marking as Manual Payment Adjustment
      isManual: true, // Mark this as a manually added remit
      payerName: data.payerName || "", // Add payerName if available
      statementStart: data.statementStart || "", // Add statementStart if available
      patientId: patientId,
      claim_adj_codes: data.claimAdjCodes,
      checkNumber: data.checkNumber,
      paymentTypeId: data.paymentTypeId || "",
    };

    // Recalculate values for new payment based on statusType
    if (newPayment.statusType === 1) {
      // Primary
      newPayment.sequestration = roundToTwo(
        newPayment.allowedAmount -
          newPayment.claimPayment -
          newPayment.patientResp
      );
      newPayment.payableAmount = roundToTwo(
        newPayment.allowedAmount - newPayment.sequestration
      );
      newPayment.summary = roundToTwo(
        newPayment.payableAmount - newPayment.claimPayment
      );
    } else if (newPayment.statusType === 2) {
      // Secondary
      newPayment.sequestration = 0;
      newPayment.summary = roundToTwo(newPayment.claimPayment);
    } else if (newPayment.statusType === 3) {
      // Clawback
      newPayment.sequestration = 0;
      newPayment.summary = roundToTwo(newPayment.claimPayment);
    } else if (newPayment.statusType === 4) {
      // Manual Payment Adjustment (Non-Remit)
      newPayment.sequestration = 0;
      newPayment.claimPayment = parseFloat(data.payment) || 0;
      newPayment.summary = roundToTwo(newPayment.claimPayment);
    } else {
      // Default case
      newPayment.sequestration = 0;
      newPayment.summary = roundToTwo(newPayment.claimPayment);
    }

    setAddedPayments((prevPayments) => [...prevPayments, newPayment]);
  };

  const handleSubmit = () => {
    if (!selectedClaimStatus) {
      setErrorMessage("Please select a claim status before submitting.");
      return; // Prevent form submission
    }

    // Clear any previous error messages
    setErrorMessage("");

    // Open the confirmation modal
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSubmit = () => {
    const dataToSubmit = {
      claimId,
      userPayload,
      claimNotes: notesList,
      remits: remits,
      selectedClaimStatus,
      claimBalance: subtotal
    };

    if (onSubmit) {
      onSubmit(dataToSubmit);
    } else {
      console.warn("No onSubmit function provided to RemitRow component.");
    }

    // Close the modal after submission
    setIsConfirmModalOpen(false);
  };

  const handleCancelSubmit = () => {
    // Simply close the modal
    setIsConfirmModalOpen(false);
  };

  const handleDeleteRemit = (id) => {
    const updatedRemits = remits.filter((remit) => remit.id !== id);
    setRemits(updatedRemits);
    setAddedPayments(addedPayments.filter((payment) => payment.id !== id));
  };

  return (
    <div className="remit-expanded">
      <div className="remit-overview panel">
        {missingPrimaryWarning && (
          <div className="warning-message">
            <p>
              A Primary Payer is missing. Please add a Primary Payer to finalize
              reconciling.
            </p>
            <br />
          </div>
        )}
        <div className="container remit-header">
          <div className="contain-50 remit-header-left">
            <p style={{ fontSize: "17px" }}>
              Claim Overview: <br />
              {claimPayer}
            </p>
          </div>
          <div className="contain-50 remit-header-right">
            <p className="remit-running-total">${subtotal.toFixed(2)}</p>
            <AddPaymentModal
              onSubmit={handleAddPayment}
              className="btn meta-add-btn"
            />
          </div>
        </div>
        <button
          style={{ float: "right" }}
          className="add-claim-note-btn"
          onClick={openClaimNotesSlideOut}
        >
          Claim Notes
        </button>
        <br />

        <table className="full-width-table">
          <thead>
            <tr>
              <th>Tags</th>
              <th>Allowed Amount</th>
              <th>Payable Amount</th>
              <th>Claim Payment</th>
              <th>Sequestration</th>
              <th>Patient Resp</th>
              <th>Payer</th>
              <th>CPT Code</th>
              <th>Date of Service</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {remits.length > 0 ? (
              remits.map((remit) => (
                <tr data-peid={remit.id} key={remit.id}>
                  <td>
                    {remit.statusType === 1
                      ? "Primary"
                      : remit.statusType === 2
                      ? "Secondary"
                      : remit.statusType === 3
                      ? "Clawback"
                      : "Manual Adjustment"}
                  </td>
                  {editMode === remit.id ? (
                    <>
                      <td>
                        <input
                          type="number"
                          value={editedRemit.allowedAmount}
                          onChange={(e) =>
                            handleInputChange(e, "allowedAmount")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedRemit.payableAmount}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedRemit.claimPayment}
                          onChange={(e) =>
                            handleInputChange(e, "claimPayment")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedRemit.sequestration}
                          disabled
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedRemit.patientResp}
                          onChange={(e) =>
                            handleInputChange(e, "patientResp")
                          }
                        />
                      </td>
                      <td>{remit.payerName}</td>
                      <td>{remit.cpt_code}</td>
                      <td>{remit.statementStart}</td>
                      <td>
                        <button onClick={() => handleSaveEdit(remit.id)}>
                          Save
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>${(remit.allowedAmount ?? 0).toFixed(2)}</td>
                      <td>${(remit.payableAmount ?? 0).toFixed(2)}</td>
                      <td>${(remit.claimPayment ?? 0).toFixed(2)}</td>
                      <td>${(remit.sequestration ?? 0).toFixed(2)}</td>
                      <td>${(remit.patientResp ?? 0).toFixed(2)}</td>
                      <td>{remit.payerName}</td>
                      <td>{remit.cpt_code}</td>
                      <td>{remit.statementStart}</td>
                      <td>
                        <button onClick={() => handleEditClick(remit)}>
                          Edit
                        </button>{" "}
                        &nbsp;
                        {(remit.statusType === 3 ||
                          remit.statusType === 4 ||
                          remit.isManual) && (
                          <button onClick={() => handleDeleteRemit(remit.id)}>
                            Delete
                          </button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10">No remit data found</td>
              </tr>
            )}
          </tbody>
        </table>

        <br />

        <ClaimNotes
          isOpen={isClaimNotesSlideOutOpen}
          claimNoteValue={claimNote}
          setClaimNoteValue={setClaimNote}
          userId={userPayload.userId}
          userName={userPayload.userName}
          claimNotesHandleAdd={claimNotesHandleAdd}
          notesList={notesList}
          closeSlideOut={closeClaimNotesSlideOut}
        />
        <br />
        <br />
        <ClaimStatusSelect
          onStatusChange={handleClaimStatusChange}
          selectedStatus={selectedClaimStatus}
        />
        <br />
        <br />

        {/* Display the error message if it exists */}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <br />
      <br />
      <button onClick={handleSubmit} className="submit-button">
        Submit
      </button>

      {/* Confirmation Modal */}
      <ClaimConfirmationModal
        isOpen={isConfirmModalOpen}
        onConfirm={handleConfirmSubmit}
        onCancel={handleCancelSubmit}
        claimStatus={selectedClaimStatus}
        subtotal={subtotal}
      />
    </div>
  );
};

export default RemitRow;
