import React from 'react';
import './ClaimConfirmationModal.scss'; // Import the modal-specific styles

const ClaimConfirmationModal = ({ isOpen, onConfirm, onCancel, claimStatus, subtotal }) => {
  if (!isOpen) return null; // Do not render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-content" role="dialog" aria-modal="true">
        <h2>Confirm Submission</h2>
        <p>
          Claim Status Selected: <strong>{claimStatus}</strong>
        </p>
        <p>
          Current Running Total: <strong>${subtotal.toFixed(2)}</strong>
        </p>
        <div className="modal-actions">
          <button className="confirm-modal-cancel-btn" onClick={onCancel}>Cancel</button>
          <button className="confirm-modal-submit-btn" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ClaimConfirmationModal;
