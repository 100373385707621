import React from 'react';
import useCpfsidAll from '../../../../../shared/hooks/useCpfsidAll';

const ClaimStatusSelect = ({ onStatusChange, selectedStatus }) => {
    const { claimStatusTypes, claimStatusTypesIsLoading, claimStatusTypesError } = useCpfsidAll();

    // Handle changes to the status selection
    const handleChange = (event) => {
        const newValue = event.target.value;
        onStatusChange(newValue); // Call the function passed as a prop to handle the status change
    };
    
    return (
        <select
            id="claimStatusSelect"
            onChange={handleChange}
            value={selectedStatus} // Set the selected value to the current status
        >            
            <option value="" disabled>Select Claim Status</option>
            {/* Map over the status types and filter out unwanted IDs */}
            {claimStatusTypes
                .filter(statusType => statusType.id !== 1 && statusType.id !== 2)
                .map((statusType) => (
                    <option key={statusType.id} value={statusType.id}>
                        {statusType.status}
                    </option>
                ))
            }
        </select>
    );
};

export default ClaimStatusSelect;
