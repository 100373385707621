import { useState, useEffect } from 'react';
import { getMultiplePaymentEvents } from '../../shared/services/paymentEventService';

const usePaymentEventsMultiple = (paymentEventIdsString) => {
    const [paymentEvents, setPaymentEvents] = useState([]);
    const [paymentEventsIsLoading, setPaymentEventsIsLoading] = useState(true);
    const [paymentEventsHasError, setPaymentEventsHasError] = useState(null);

    useEffect(() => {
        if (!paymentEventIdsString) return; // Early return if the string is empty

        const fetchPaymentEventsMultiple = async () => {
            try {
                // Convert the string back into an array for the API call
                const paymentEventIds = paymentEventIdsString.split(",");
                const paymentEventData = await getMultiplePaymentEvents(paymentEventIds);
                
                if (!paymentEventData) {
                    throw new Error('No Payment Event Data Found');
                }
                setPaymentEvents(paymentEventData);
            } catch (error) {
                console.error('Error in fetchPaymentEventsMultiple:', error);
                setPaymentEventsHasError(error);
            } finally {
                setPaymentEventsIsLoading(false);
            }
        };

        fetchPaymentEventsMultiple();
    }, [paymentEventIdsString]);

    return { paymentEvents, paymentEventsIsLoading, paymentEventsHasError };
};

export default usePaymentEventsMultiple;
