import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../../style.scss";
import "./claims.scss";
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import AddClaimModal from "../Claims/components/AddClaimModal/AddClaimModal";

// ** Custom Hook for useClaims
import useClaims from "./hooks/useClaims";

const Claims = () => {
  const { user, isLoading: authLoading } = useAuth0();
  const { claims, claimsIsLoading } = useClaims();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cpfsidFilter, setCpfsidFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [applyFilters, setApplyFilters] = useState(false);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSort = (key) => {
    setSortConfig((prevSortConfig) => {
      let direction = "asc";
      // Toggle direction if the same key is clicked
      if (prevSortConfig.key === key && prevSortConfig.direction === "asc") {
        direction = "desc";
      }
      return { key, direction };
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setApplyFilters(false);
  }, [searchQuery, cpfsidFilter, startDate, endDate]);

  const handleClearFilters = () => {
    setSearchQuery("");
    setCpfsidFilter("");
    setStartDate("");
    setEndDate("");
    setApplyFilters(false);
  };

  // Apply filters and sorting
  const filteredClaims = claims
    ?.filter((claim) =>
      Object.values(claim).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery)
      )
    )
    ?.filter((claim) => {
      if (cpfsidFilter) {
        return claim.cpfsid === parseInt(cpfsidFilter, 10);
      }
      return true;
    })
    ?.filter((claim) => {
      if (applyFilters && startDate && endDate) {
        const claimDate = new Date(claim.from_date);
        return (
          claimDate >= new Date(startDate) && claimDate <= new Date(endDate)
        );
      }
      return true;
    })
    ?.sort((a, b) => {
      if (sortConfig.key) {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (sortConfig.direction === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      }
      return 0;
    });

  // Apply pagination
  const paginatedClaims = filteredClaims?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="claims">
      <HeaderSidebar
        title="All Claims"
        subcategory=""
        subtitle="All Claims Worksheet"
      />
      <div className="main-content">
        <div className="content-area">          
          <div className="contain-100">
            <AddClaimModal />
            <TextField
              label="Search claims..."
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              className="search-input"
              fullWidth
            />
            <br />
            <br />
            <select
              value={cpfsidFilter}
              onChange={(e) => setCpfsidFilter(e.target.value)}
              className="filter-select"
            >
              <option value="">All Statuses</option>
              <option value="2">Unprocessed</option>
              <option value="100">Submitted</option>
              <option value="200">Rejected</option>
              <option value="300">Open</option>
              <option value="400">Closed</option>
              <option value="500">Appealed</option>
              <option value="600">Collections</option>
              <option value="700">Denied</option>
            </select>
            <br />
            <br />
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="date-input"
              InputLabelProps={{
                shrink: true,
              }}
            />
            &nbsp;&nbsp;
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="date-input"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setApplyFilters(true)}
            >
              Apply Filters
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClearFilters}
              style={{ marginLeft: "10px" }}
            >
              Clear Filters
            </Button>
            <br />
            <br />
          </div>

          {/* Total Claims Counter */}
          <div className="total-claims">
            <strong>Total Claims: {filteredClaims?.length || 0}</strong>
          </div>
          <br />

          <div className="panel">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "cpfsid"}
                      direction={
                        sortConfig.key === "cpfsid"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("cpfsid")}
                    >
                      Claim Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "patient_name"}
                      direction={
                        sortConfig.key === "patient_name"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("patient_name")}
                    >
                      Patient Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "patient_id"}
                      direction={
                        sortConfig.key === "patient_id"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("patient_id")}
                    >
                      Patient Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "from_date"}
                      direction={
                        sortConfig.key === "from_date"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("from_date")}
                    >
                      Date of Service
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Payer Name</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "claim_balance"}
                      direction={
                        sortConfig.key === "claim_balance"
                          ? sortConfig.direction
                          : "asc"
                      }
                      onClick={() => handleSort("claim_balance")}
                    >
                      Current Balance
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsIsLoading ? (
                  <TableRow>
                    <TableCell colSpan={7}>Claims Loading...</TableCell>
                  </TableRow>
                ) : paginatedClaims && paginatedClaims.length > 0 ? (
                  paginatedClaims.map((claim) => (
                    <TableRow key={claim.id} className="claim-row">
                      <TableCell>{claim.cpfsid}</TableCell>
                      <TableCell>{claim.patient_name}</TableCell>
                      <TableCell>{claim.patient_id}</TableCell>
                      <TableCell>{claim.from_date}</TableCell>
                      <TableCell>{claim.payer_name}</TableCell>
                      <TableCell>${claim.claim_balance}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            navigate(`/billing/claims/${claim.id}`)
                          }
                        >
                          View Claim
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>There are NO records</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredClaims?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claims;
