import { useState, useEffect } from 'react';
import { unprocessedClaims } from '../../../../shared/services/claimService';

const useClaimsUnprocessed = () => {
    const [ claims, setClaims ] = useState([]);
    const [ claimsIsLoading, setClaimsIsLoading ] = useState(true);
    const [ claimsHasError, setClaimsHasError ] = useState(null);

    useEffect(() => {
        const fetchClaims = async () => {
          try {
            const claimsData = await unprocessedClaims();
            if (!claimsData) {
              throw new Error('No claims data received');
            }
            setClaims(claimsData);
          } catch (error) {
            console.error('Error in fetchClaims:', error);
            setClaimsHasError(error);
          } finally {
            setClaimsIsLoading(false);
          }
        };
        fetchClaims();
      }, []);
      
    
    return { claims, claimsIsLoading, claimsHasError };
};

export default useClaimsUnprocessed;