import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import PaymentEventRow from './PaymentEventRow';

const PaymentEventsTable = ({ paymentEvents }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Patient ID</TableCell>
            <TableCell>Claim Payment</TableCell>
            <TableCell>Payer Name</TableCell>
            <TableCell>Claim Status Code</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Check Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentEvents.map((event) => (
            <PaymentEventRow key={event.id} event={event} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentEventsTable;
