import axios from 'axios';

// Create an Axios instance with a base URL and default headers
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,  // Backend base URL from .env
  headers: {
    'Content-Type': 'application/json',         // Default content type
    // Optionally add Authorization or other headers here
  },
});

export default apiClient;