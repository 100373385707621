import { useState, useEffect } from 'react';
import { getAllClaimStatuses } from '../../../src/shared/services/cpfsidService';

const useCpfsidAll = () => {

    const [ claimStatusTypes, setClaimStatusTypes ] = useState([]);
    const [ claimStatusTypesIsLoading, setClaimStatusTypesIsLoading ] = useState(null);
    const [ claimStatusTypesError, setClaimStatusTypesError ] = useState(null);

    useEffect(() => {
        const fetchClaimStatusTypes = async () => {
            try {
                const claimStatusTypesData = await getAllClaimStatuses();
                if(!claimStatusTypesData){
                    throw new Error('No Claim Status data received');
                }
                setClaimStatusTypes(claimStatusTypesData);
            } catch (error) {
                console.error('Error in fetchClaimStatusTyoes');
                setClaimStatusTypesError(error);
            } finally {
                setClaimStatusTypesIsLoading(false);
            }
        }
        
        fetchClaimStatusTypes();
    }, []);

    return { claimStatusTypes, claimStatusTypesIsLoading, claimStatusTypesError };

}

export default useCpfsidAll;