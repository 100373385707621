import React from "react";
import './ClaimNotes.scss';

const ClaimNotes = ({ isOpen, claimNoteValue, setClaimNoteValue, userId, userName, claimNotesHandleAdd, notesList, closeSlideOut }) => {
  return (
    <>
      {/* Conditional rendering of the overlay */}
      {isOpen && (
        <div
          style={styles.modalBackdrop}
          onClick={closeSlideOut}
        />
      )}

      {/* Slide-out container */}
      <div style={{ ...styles.slideOutContainer, right: isOpen ? '0' : '-450px' }}>
        <button style={styles.closeButton} onClick={closeSlideOut}>&times;</button>
        <p style={{ fontSize: "18px" }}>Claim Notes:</p>
        <br />
        <div>
          <p>Create a New Note:</p>
          <textarea 
            value={claimNoteValue} 
            onChange={(e) => setClaimNoteValue(e.target.value)} 
            style={{ width: '100%', height: '100px' }}
          />
          <button className="add-note-btn" onClick={claimNotesHandleAdd}>Add Note</button>
          <br /><br />
        
          <p style={{ fontSize: '16px' }}>Previous Notes:</p>
          <div className="notes-container">
            <ul className="note-list">
              {notesList.map((note, index) => (
                <li key={index}>{note.userName}:<br/> {note.text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(154, 170, 185, 0.5)',
    zIndex: 1000, // Ensure it's behind the slide-out
  },
  slideOutContainer: {
    position: 'fixed',
    top: 0,
    right: '-450px', // Start offscreen
    bottom: 0,
    width: '450px',
    backgroundColor: 'white',
    padding: '20px',
    transition: 'right 0.3s ease-in-out', // Smooth slide effect
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.09)',
    zIndex: 1100, // Ensure it's above the backdrop
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    fontSize: '25px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    fontWeight: '300',
  }
};

export default ClaimNotes;
