import apiClient from '../../api/apiClient';

export const getAllPaymentEventTypes = async () => {
    try {
        const response = await apiClient.get('/billing/payment-event-types')
        return response.data.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getPaymentEventTypeById = async (paymentEventId) => {
    try {
        const response = await apiClient.get(`/billing/payment-event-types/${paymentEventId}`);
    } catch (error) {
        console.log(error);
        throw error;
    }
}