import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './App.scss';
import './fonts.css';
import './style.scss';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Loader from './images/smiles-loader.gif';

import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Dashboard from './features/Dashboard/Dashboard';
import ClaimsUnprocessed from './features/Billing/Claims/ClaimsUnprocessed';
import Claims from './features/Billing/Claims/Claims';
import ClaimDetails from './features/Billing/Claims/ClaimDetails';

import LoginButton from './components/LoginButton/LoginButton';

const App = () => {
    const { user, isLoading, isAuthenticated } = useAuth0();
    return (
        <>
        {isLoading && (
            <div className="loading">
                <img src={Loader} className="loader-gif" alt="Smiles Loading Gif" />
            </div>
        )}

        {/* Is not logged in */}
        {!isAuthenticated && (
            <>
                <div>Not Authenticated</div>
                <LoginButton />
            </>
        )}


        {/* IS logged in */}
        {isAuthenticated && (
            <Router>
                <div className="App">                                        
                    <Routes>
                        <Route index element={<Dashboard/>}></Route>
                        <Route path="/billing/claims/" element={<Claims/>}></Route>
                        <Route path="/billing/claims/:claimId" element={<ClaimDetails/>}></Route>
                        <Route path="/billing/claims/unprocessed" element={<ClaimsUnprocessed/>}></Route>
                        {/* <Route path="/patients" element={<Patients/>}></Route>
                        <Route path="/patients/demographics/:id" element={<PatientDetails/>}></Route>
                        <Route path="/profile" element={<Profile/>}></Route>
                        <Route path="/grid" element={<Grid/>}></Route>
                        <Route path="/billing" element={<Billing/>}></Route>
                        <Route path="/billing/claims" element={<Claims/>}></Route>
                        <Route path="/billing/remits/:patientId" element={<Remits/>}></Route>

                        <Route path="/billing/claims/denials" element={<ClaimsDenied/>}></Route>
                        <Route path="/billing/claims/denials/:claimId" element={<ClaimsDeniedDetails/>}></Route>
                        <Route path="/billing/claims/collections" element={<Collections/>}></Route> */}
                    </Routes>
                </div>
            </Router>
        )}
        </>
    );
}


export default App;