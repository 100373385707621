import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../style.scss";
import "./claims.scss";
import HeaderSidebar from "../../../components/HeaderSidebar/HeaderSidebar";
import { useParams } from "react-router-dom";
import useClaimDetails from "./hooks/useClaimDetails";
import usePaymentEventsMultiple from "../../../shared/hooks/usePaymentEventsMultiple";
import PaymentEventsTable from "./components/ClaimDetails/PaymentEventTable";
import ClaimStatusSelect from "./components/ClaimStatusSelect/ClaimStatusSelect";
import ClaimNotesList from "./components/ClaimNotesList/ClaimNotesList";

const ClaimsDetail = () => {
  const { claimId } = useParams(); // Grab the claim ID from the URL
  const { claim, claimIsLoading, claimHasError } = useClaimDetails(claimId);
  console.log(claim)
  // Local state for the current status
  const [currentStatus, setCurrentStatus] = useState("");

  // Set the initial status when claim data is available
  useEffect(() => {
    if (claim && claim.cpfsid) {
      setCurrentStatus(claim.cpfsid);
    }
  }, [claim]);

  // Check if claim and payment_event_ids are defined
  const paymentEventIds = claim && claim.payment_event_ids ? claim.payment_event_ids : "";
  const { paymentEvents, paymentEventsIsLoading, paymentEventsHasError } = usePaymentEventsMultiple(paymentEventIds);

  // Function to handle status change
  const handleStatusChange = (newStatus) => {
    // Update the local state
    setCurrentStatus(newStatus);

    // Here you would call your service to update the status in the database
    console.log("New Status Selected:", newStatus);
    // Add your API call to update the status here
  };

  if (claimIsLoading || paymentEventsIsLoading) {
    return <div>Loading...</div>;
  }

  if (claimHasError || paymentEventsHasError) {
    return <div>Error loading claim details.</div>;
  }

  return (
    <div className="claim-details">
      <HeaderSidebar title="Claim Detail" subcategory="" subtitle="Claim Detail Page" />
      <div className="main-content">
        <div className="content-area">
          <div className="panel">
            <p>Patient: {claim.patient_name}</p>
            <p>Current Balance: ${claim.claim_balance == null ? "0.00" : claim.claim_balance}</p>
            <p>Payer: {claim.payer_name}</p>
            <p>Current Status: {currentStatus}</p>
            <p>Date of Service: {claim.from_date}</p>
            <p>Patient Id: {claim.patient_id}</p>
            <p>Units: {claim.units}</p>
          </div>
          <br />
          <div className="panel">
            <PaymentEventsTable paymentEvents={paymentEvents} />
          </div>
          <br /><br />
          <p>Claim Notes:</p>
          <ClaimNotesList claimId={claim.id} />
          <br /><br />
          <br />
          <ClaimStatusSelect selectedStatus={currentStatus} onStatusChange={handleStatusChange} />
          <button className="submit-button">Save</button>
        </div>
      </div>
    </div>
  );
};

export default ClaimsDetail;